<template>
	<button v-on:click="toggle" title="Open site navigation" aria-label="Open site navigation">
		<span v-if="'Home' !== route_name" class="mb-0 font-small mr-2 nav-hint" v-html="route_name"></span>

		<Bars class="bars" />
	</button>

	<nav @keydown.esc="toggle" id="mastnav" class="nav" :class="true === active ? 'nav-active' : false + ' ' + extra_class ? extra_class : ''">
		<div class="nav-header p-3 mb-5">
			<button class="times-wrap" v-on:click="toggle" title="Close site navigation" aria-label="Close site navigation">
				<Times class="times" />
			</button>
		</div>

		<div class="nav-container row no-gutters align-items-center">
			<div class="container container--sm">
				<ul class="nav-items">
					<li class="nav-item" v-for="item in items" :key="item.name">
						<div v-if="typeof item.meta.follow !== 'undefined' && false === item.meta.follow">
							<button class="blank font-large color--mono-black nav-item__anchor nav-item__subTrigger" @click="showSubNav(item.name.toLowerCase() + '_subNavigation', $event)" v-html="item.name" :aria-label="'Open ' + item.name + ' sub navigation'"></button>
							<ul :id="item.name.toLowerCase() + '_subNavigation'" v-if="item.children.length > 0" class="sub-menu">
								<li v-for="child in item.children" :key="child.name">
									<router-link class="font-sub color--mono-black nav-item__anchor" :to="{name: child.name}" v-html="child.name"></router-link>
								</li>
							</ul>
						</div>
						<router-link v-else class="font-large color--mono-black nav-item__anchor" :to="{name: item.name}" v-html="item.name"></router-link>
					</li>
				</ul>

				<Socials class="mt-5" />
				<Details class="nav-details" />
			</div>
		</div>

		<div class="nav-background bg--mono-white"></div>
	</nav>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import Routes from '@/router';
import Bars from '@/assets/images/svgs/bars.svg?external';
import Times from '@/assets/images/svgs/times.svg?external';
import Socials from '@/components/partials/Socials';
import Details from '@/components/partials/Details';
import ProjectsObject from '@/assets/projects';
import _ from 'lodash';

export default {
	name: 'Navigation',

	components: {
		Bars,
		Times,
		Socials,
		Details,
	},

	data() {
		return {
			items: [],
			active: false,
			extra_class: false,
			route_name: this.$route.name,
		}
	},

	watch:{
		$route(){
			this.route_name = this.$route.name;

			if ( 'Project' === this.route_name ) {
				let slug = this.$route.params.slug.replaceAll('-', '');
				let project = ProjectsObject[slug];

				if ( typeof project !== 'undefined' ) {
					this.route_name = project.name;					
				}
			}

			const mastnav = document.getElementById('mastnav');
			mastnav.classList.add('nav-closed');

			let self = this;

			setTimeout(function() {
				mastnav.classList.remove('nav-active');
				mastnav.classList.remove('nav-closed');

				// remove active states on sub menus.
				document.querySelectorAll('.sub-menu.active').forEach(sub_menu => sub_menu.classList.remove('active'));
				document.querySelectorAll('.nav-item__subTrigger.active').forEach(trigger => trigger.classList.remove('active'));

				if ( typeof self.getNavItems() !== 'undefined' ) {
					self.getNavItems();
				}
			}, 750);
		}
	},

	created() {
		this.getNavItems();

		/**
		* on esc press hide nav
		*/

		document.addEventListener('keydown',function(event){
			if ( event.keyCode == 27 ) {
				const mastnav = document.getElementById('mastnav');
				mastnav.classList.add('nav-closed');

				setTimeout(function() {
					mastnav.classList.remove('nav-active');
					mastnav.classList.remove('nav-closed');

					// remove active states on sub menus.
					document.querySelectorAll('.sub-menu.active').forEach(sub_menu => sub_menu.classList.remove('active'));
					document.querySelectorAll('.nav-item__subTrigger.active').forEach(trigger => trigger.classList.remove('active'));

					if ( typeof self.getNavItems() !== 'undefined' ) {
						self.getNavItems();
					}
				}, 750);
			}
		}, false);
	},

	methods: {
		getNavItems() {
			this.items = Routes.getRoutes().filter(item => true === item.meta.nav);

			if ( this.items.length > 0 ) {
				let parents = new Array();

				// add all parents into an object.
				Routes.getRoutes().map( item => typeof item.meta.parent !== 'undefined' && parents.indexOf( item.meta.parent ) < 0 ? parents.push( item.meta.parent ) : false );

				// loop through each parent, and whack children into childen key.
				parents.forEach( parent => {
					let children = Routes.getRoutes().filter( route => typeof route.meta.parent !== 'undefined' && parent === route.meta.parent );
					this.items.find( route => parent === route.path ).children = children;
				} );

				// filter by order meta
				this.items = _.orderBy( this.items, route => route.meta.order, 'asc' );
			}
		},

		showSubNav: function ( sub_menu, event ) {
			if ( ! sub_menu ) {
				return false;
			}
			
			document.getElementById(sub_menu).classList.toggle('active');
			event.target.classList.toggle('active');

			this.items = this.items.filter( item => item.children.length > 0 );

			if ( false === document.getElementById(sub_menu).classList.contains('active') ) {
				this.getNavItems();
			}
		},

		toggle: function () {
			const mastnav = document.getElementById('mastnav');

			if ( false === mastnav.classList.contains('nav-active') ) {
				mastnav.classList.add('nav-active');
			} else {
				mastnav.classList.add('nav-closed');

				setTimeout(function() {
					mastnav.classList.remove('nav-active');
					mastnav.classList.remove('nav-closed');

					// remove active states on sub menus.
					document.querySelectorAll('.sub-menu.active').forEach(sub_menu => sub_menu.classList.remove('active'));
				}, 750);
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	.nav { 
		display: none;
		position: fixed;
		z-index: 10;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		overflow-x: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		&.nav-active {
			display: block;

			.times {
				opacity: 0;
			}
		}

		&.nav-closed {
			ul,
			.times {
				animation: fade-out 500ms ease-in-out forwards;
			}

			.nav-background {
				bottom: 0;
				animation: slide-up 500ms 250ms ease-in-out forwards;
			}
		}

		li {
			&:not(:last-child) {
				padding-bottom: $spacer * 1.33;
			}
		}
	}

	.nav-items {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	.nav-container {
		position: fixed;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		animation: fade-in 500ms 250ms ease-in-out forwards;
	}

	.nav-background {
		position: absolute;
		z-index: -1;
		bottom: 100%;
		left: 0;
		width: 100%;
		height: 100%;
		animation: slide-down 500ms ease-in-out forwards;
	}

	.nav-item {
		padding-left: 0;
	}

	.nav-item__anchor {
		font-weight: 900;
		border-bottom: 4px solid transparent;

		&.router-link-exact-active {
			border-color: currentColor;
		}
	}

	.nav-item__subTrigger {
		&:before, 
		&:after {
			display: inline-block;
			vertical-align: middle;
			content: '';
			width: 0; 
			height: 0; 
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 8px solid currentColor;
			margin-left: $spacer;
		}

		&:before {
			content: none;
			margin-left: 0;
			margin-right: $spacer;
		}

		&.active {
			&:after {
				content: none;
			}

			&:before {
				content: '';
				transform: rotate(90deg);
			}
		}
	}

	.nav ul.sub-menu {
		display: none; 

		&.active {
			display: block;

			li {
				opacity: 0;
				animation: slide-in 125ms forwards;

				@for $i from 1 through 20 {
					&:nth-child(#{$i}){
						animation-delay: $i * 75ms;
					}
				}
			}
		}

		li:not(:last-child) {
			padding-bottom: $spacer / 2;
		}

		li {
			a {
				position: relative;
				z-index: 0;

				&:before {
					content: '\2014';
					margin-right: $spacer;
				}
			}
		}
	}

	.bars {
		width: 30px;
		height: 26.25px;
		fill: color(mono, white); 
	}

	.times-wrap {
		display: block;
		margin-left: auto;
		position: relative;
		z-index: 5;
	}

	.times {
		width: 30px;
		height: auto;
		fill: color(mono, black); 
		margin-top: -5px;
		animation: fade-in 500ms 250ms ease-in-out forwards;
	}

	.nav-details {
		margin-bottom: 0 !important;
	}

	.nav-hint {
		display: none;

		@include media-breakpoint-up(md) {
			display: inline-block;
		}
	}
</style>