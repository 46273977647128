<template>
	<article v-if="is_visible" class="project" :id="$filters.sanitize_string(data.name)">
		<div class="row no-gutters">
			<div class="project-thumbnail col-11">
				<router-link @mouseover="fauxHover(true)" @mouseleave="fauxHover(false)" :to="{ path: '/project/' + data.slug }">
					<img loading="lazy" width="505" height="675" :src="require( '../assets/' + data.thumbnail.src )" :alt="data.thumbnail.alt">
				</router-link>
			</div>

			<div class="project-detail mt-4 col-11">
				<router-link @mouseover="fauxHover(true)" @mouseleave="fauxHover(false)" :to="{ path: '/project/' + data.slug }">
					<h3 class="font-responsive-extra-small mb-1 project-name" v-html="data.name" />
				</router-link>

				<div v-if="typeof data.tags !== 'undefined' && data.tags.length > 0" class="project-tags">
					<ul class="row no-gutters">
						<li v-for="(tag, index) in data.tags" :key="tag.name" class="color--mono-black font-reset">
							<span :to="{path: tag.path}" v-html="tag.name" />
							<span v-if="data.tags.length !== index + 1">,&nbsp;</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
	export default {
		name: 'Project',

		props: [ 'data' ],

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		},

		methods: {
			fauxHover( state ) {
				let item = document.getElementById( this.$filters.sanitize_string( this.data.name ) );

				if ( true === state && false !== item ) {
					item.classList.add('hovered');
				}

				if ( false === state && false !== item ) {
					item.classList.remove('hovered');
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.project {
		position: relative;
		z-index: 0;

		&-name {
			display: inline-block;
		}

		&-thumbnail {
			overflow: hidden;

			img {
				transition: transform ease-in-out 350ms;
				will-change: transform;
			}

			&:hover {
				img {
					transform: scale(1.0125);
				}
			}
		}

		&.hovered {
			.project-thumbnail img {
				transform: scale(1.0125);
			}

			.project-name {
				color: color( brand, accent )
			}
		}

		&:after {
			content: '';
			position: absolute;
			z-index: -1;
			margin-top: 1px;
			top: - $spacer * 3;
			right: 0;
			width: 150px;
			height: 150px;
			background-image: url('../assets/images/svgs/ears-tiled.svg?inline');
			background-size: 54%;
			opacity: 0.33;
		}
	}
</style>