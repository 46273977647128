<template>
	<section v-if="is_visible" class="list py-5" :class="typeof data.backgroundColor !== 'undefined' ? data.backgroundColor : 'bg--brand-flat'">
		<div class="container">
			<h2 class="list-title font-responsive mb-0" v-html="data.title"></h2>

			<div v-if="typeof data.body !== 'undefined'" class="mt-4 list-text" v-html="$filters.autop(data.body)"></div>

			<ul class="list-items row mt-4">
				<li class="col-12 font-mid mb-3" :class="typeof data.item_width !== 'undefined' ? data.item_width : 'col-md-4'" v-for="item in data.items" :key="item">
					<Check/>

					<div class="list-item" v-html="item"></div>
				</li>
			</ul>

			<div v-if="typeof data.signoff !== 'undefined'" class="mt-5 list-text" v-html="$filters.autop(data.signoff)"></div>
		</div>
	</section>
</template>

<script>
	import Check from '@/assets/images/svgs/check.svg?external';

	export default {
		name: 'List',

		props: [ 'data' ],

		components: {
			Check
		},

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.list {
		svg {
			fill: color( brand, accent );
			width: 35px;
			height: auto;
			display: inline-block;
			vertical-align: middle;
			margin-right: $spacer * 2;
			margin-bottom: $spacer / 2;
		}

		&-title {
			max-width: 1000px;
		}

		&-item {
			font-weight: 600;
		}

		&-items {
			margin-bottom: - $spacer * 3 !important;
		}

		&-text {
			max-width: 1000px;
		}
	}
</style>