import bridgehousebarn from '@/assets/projects/bridge-house-barn.json';
import crawfordstrategic from '@/assets/projects/crawford-strategic.json';
import letsgetsharp from '@/assets/projects/lets-get-sharp.json';
import trainingshed from '@/assets/projects/training-shed.json';

export default {
	bridgehousebarn,
	crawfordstrategic,
	letsgetsharp,
	trainingshed
}