<template>
	<ul class="sitemap row no-gutters mb-3">
		<li><a href="/privacy" title="Privacy">Privacy</a></li>
		<li><a href="/contact" title="Contact">Contact</a></li>
	</ul>
</template>

<style lang="scss" scoped>
	.sitemap {
		li:not(:last-child) {
			&:after {
				content: '|';
				display: inline-block;
				margin: 0 10px;
			}
		}

		a {
			color: color(mono, black);
		}
	}
</style>