<template>
	<section v-if="is_visible" class="quad py-5" :class="typeof data.backgroundColor !== 'undefined' ? data.backgroundColor : 'bg--mono-white'">
		<div class="container">
			<div class="quad-header">
				<h2 class="quad-header_title font-responsive" v-html="data.title"></h2>	

				<div v-if="typeof data.body !== 'undefined'" class="mt-3 quad-text" v-html="$filters.autop(data.body)"></div>
			</div>

			<div v-if="data.items" class="row quad-row mt-3">
				<div v-for="column in columns" :key="column" class="col-12 quad-item_wrapper pr-md-4" :class="typeof data.item_width !== 'undefined' ? data.item_width : 'col-md-auto'">
					<article class="quad-item mb-3" v-for="item in column" :key="item.title">
						<component :is="data.item_tag || 'h3'" class="font-responsive-extra-small quad-item_title mb-0" v-html="item.title"></component>

						<div v-if="typeof item.body !== 'undefined'" class="mt-2" v-html="$filters.autop(item.body)"></div>
					</article>
				</div>
			</div>

			<div v-if="typeof data.signoff !== 'undefined'" class="quad-text mt-4" v-html="$filters.autop(data.signoff)"></div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Quad',
		
		props: [ 'data' ],

		data() {
			return {
				columns: []
			}
		},

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		},

		created() {
			if ( typeof this.data !== 'undefined' ) {
				let first_half = this.data.items.slice( 0, Math.ceil( this.data.items.length / 2 ) )
				let second_half = this.data.items.slice( Math.ceil( this.data.items.length / 2 ) );

				this.columns = new Array();
				this.columns.push( first_half, second_half );
			}
		}
	}
</script>

<style lang="scss" scoped>
	.quad-header_title {
		max-width: 1000px;
	}

	.quad-item_title {
		position: relative;
		z-index: 0;
		max-width: 85%;

		&:before {
			content: '\00B7';
			position: relative;
			z-index: 0;
			margin-left: -2.5px;
			top: 5.5px;
			margin-right: $spacer;
			font-size: 3rem;
			line-height: 1;
			color: color( brand, accent );
		}
	}

	p.quad-item_title {
		font-size: font(sub);
		font-weight: 700;
	}

	.quad-text,
	.quad-item {
		max-width: 1000px;

		&::v-deep p:last-child {
			margin-bottom: 0;
		}
	}

	.quad-item_wrapper:not(.col-md-12) {
		max-width: 750px;
	}

	.quad-item_wrapper.col-md-12 {
		max-width: 1000px;

		.quad-item_title {
			max-width: 100%;
		}

		p {
			max-width: 100%;
		}
	}

	.quad-item_wrapper.col-md-auto {
		.quad-item_title {
			max-width: 100%;
		}
	}

	.quad-row {
		margin-bottom: - $spacer * 2;
	}
</style>