<template>
	<section v-if="is_visible" class="testimonial py-5">
		<div class="container container--md">
			<div class="testimonial-body mb-3 font-responsive-extra-small" v-html="$filters.autop(data.body)"></div>

			<p class="testimonial-author font-mid mb-0" v-html="data.author"></p>
			<p v-if="typeof data.role !== 'undefined'" class="testimonial-role font-reset mb-0" v-html="data.role"></p>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Testimonial',
		
		props: [ 'data' ],

		computed: {
			is_visible() {
				if ( typeof this.data !== 'undefined' && ( typeof this.data.active !== 'undefined' && false === this.data.active ) ) {
					return false;
				}

				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.testimonial {
		position: relative;
		z-index: 0;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0; 
			left: 0;
			width: 100%;
			height: 50%;
			background: rgb(255,255,255);
			background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		}

		&:after {
			content: '';
			position: absolute;
			z-index: -2;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../assets/images/svgs/ears-tiled_accent.svg?inline');
			opacity: 0.033;
			background-size: 200px;
		}

		&-body {
			position: relative;
			z-index: 0;

			&::v-deep p {
				font: inherit;
				line-height: 1.8;

				&:first-of-type:before {
					content: open-quote;
					font-size: 8rem;
					line-height: 0.5;
					display: block;
					margin-bottom: - $spacer * 1.33;
				}
			}
		}

		&-author {
			font-weight: 900;
		}

		&-role {
			font-weight: 700;
			color: darken( color( brand, flat ), 25% );
		}
	}
</style>