import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes.js';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // check for 404.
    if ( ! to.matched.length ) {
        next('/404');
    }

    // set title & meta description
    document.title = null !== to.meta.title ? to.meta.title + ' — Kaluna' : to.name + ' — Kaluna';

    let meta_description = document.querySelector( 'head meta[name="description"]' );
    meta_description.setAttribute( 'content', to.meta.description );

    // do next.
    next();
})

router.afterEach(() => {
    window.scrollTo(0, 0);
})

export default router
