export const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: "Bespoke web design and development based in Leicester",
            description: "Show the world your brand with a bespoke, hand-coded WordPress website, designed and developed by Kaluna in partnership with your business.",
            nav: true,
            order: 1
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Layout.vue')
    },
    {
        path: '/404',
        name: '404 Not Found',
        meta: {
            title: "404 Not Found",
            nav: false
        },
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue')
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title: "About",
            description: "Learn more about your new team, Kaluna. We have the experience, expertise and creativity to bring your ideas for your website to life in a way you'll love.",
            nav: true,
            order: 2
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Layout.vue')
    },
    {
        path: '/projects',
        name: 'Projects',
        meta: {
            title: "Projects",
            description: "TBC",
            nav: true,
            order: 4
        },
        component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue')
    },
    {
        path: '/project/:slug',
        name: 'Project',
        meta: {
            nav: false
        },
        component: () => import(/* webpackChunkName: "privacy" */ '../views/Project.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: "Contact - Bespoke web design and development based in Leicester",
            description: "TBC",
            nav: true,
            order: 5
        },
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    {
        path: '/services',
        name: 'Services',
        meta: {
            title: "Contact - Bespoke web design and development based in Leicester",
            nav: true,
            order: 3,
            follow: false
        },
        component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
    },
    {
        path: '/web-design',
        name: 'Web Design',
        meta: {
            parent: '/services',
            title: "Custom WordPress Web Design & Development",
            description: "Showcase your business in style with a bespoke WordPress web design, custom-made by Kaluna to help your brand stand out online.",
            nav: false,
            content: 'WebDesign.json'
        },
        component: () => import(/* webpackChunkName: "web-design" */ '../views/Service.vue')
    },
    {
        path: '/branding-design',
        name: 'Brand and logo design',
        meta: {
            parent: '/services',
            title: "Professional Logo Design and Brand Identity Company",
            description: "Connect with your customers through an instantly recognisable brand identity and logo design by Kaluna that communicates what your business is about.",
            nav: false,
            content: 'BrandLogoDesign.json'
        },
        component: () => import(/* webpackChunkName: "branding-design" */ '../views/Service.vue')
    },
    {
        path: '/support-retainers',
        name: 'Website Support Retainers',
        meta: {
            parent: '/services',
            title: "WordPress Maintenance & Support Services",
            description: "Trust our team to keep your website working reliably for your business with our professional WordPress maintenance and support packages.",
            nav: false,
            content: 'WebSupport.json'
        },
        component: () => import(/* webpackChunkName: "support-retainers" */ '../views/Service.vue')
    },
    {
        path: '/domains-hosting',
        name: 'Domains &amp; Hosting',
        meta: {
            parent: '/services',
            title: "Domain Name and Web Hosting Services",
            description: "Find the perfect domain name to represent your business and enjoy fast, reliable and secure web hosting thanks to Kaluna, all for a competitive price.",
            nav: false,
            content: 'DomainsHosting.json'
        },
        component: () => import(/* webpackChunkName: "domains-hosting" */ '../views/Service.vue')
    },
    {
        path: '/seo-services',
        name: 'SEO Services',
        meta: {
            parent: '/services',
            title: "SEO Services for Local, National and E-commerce clients",
            description: "Find the perfect domain name to represent your business and enjoy fast, reliable and secure web hosting thanks to Kaluna, all for a competitive price.",
            nav: false,
            content: 'SEOServices.json'
        },
        component: () => import(/* webpackChunkName: "domains-hosting" */ '../views/Service.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        meta: {
            title: "Privacy",
            nav: false
        },
        component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
    },
    {
        path: '/thanks',
        name: 'Thanks',
        meta: {
            title: "Thanks",
            nav: false
        },
        component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue')
    }
]