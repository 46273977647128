<template>
	<section v-if="is_visible" class="projects bg--reset py-5">
		<div class="container">
			<h2 class="projects-title mb-5 font-responsive" v-if="data.title">
				<router-link :to="{path: '/projects'}" v-html="data.title"/>
			</h2>

			<div class="projects-row row justify-content-center">
				<div class="col-12 col-md-5 project-item" v-for="project in projects" :key="project.name">
					<Project class="mb-4" :data="project"/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import ProjectObject from '@/assets/projects/';

	export default {
		name: 'Projects',

		props: [ 'data' ],

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		},

		data() {
			return {
				projects: []
			}
		},

		created() {
			this.projects = Object.values(ProjectObject).filter( project => typeof project.featured !== 'undefined' && true === project.featured ).splice( 0, this.data.amount );
		}
	}
</script>

<style lang="scss" scoped>
	.projects {
		position: relative;
		z-index: 0;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0; 
			left: 0;
			width: 100%;
			height: 50%;
			background: rgb(255,255,255);
			background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		}

		&:after {
			content: '';
			position: absolute;
			z-index: -2;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../assets/images/svgs/ears-tiled_accent.svg?inline');
			opacity: 0.033;
			background-size: 200px;
		}

		@include media-breakpoint-up(xxl) {
			.project-item {
				margin-left: $spacer * 4;
				margin-right: $spacer * 4;
			}
		}
	}

	.projects-title {
		text-align: center;
	}

	.projects-row {
		margin-bottom: -$spacer * 4;

		@include media-breakpoint-up(md) {
			.project-item:nth-of-type(even) {
				margin-top: $spacer * 6;
			}
		}

		@include media-breakpoint-up(lg) {
			.project-item:nth-of-type(even) {
				margin-top: $spacer * 10;
			}
		}
	}
</style>