<template>
	<div class="button-wrap">
		<router-link :style="item" v-if="typeof target === 'undefined'" :class="'button-item ' + css" :to="to">
			<span>{{ title }}</span>
			<div class="button-mask"></div>
		</router-link>

		<a v-else :href="to" target="_blank" class="button-item">
			<span>{{ title }}</span>
			<div class="button-mask"></div>
		</a>
	</div>
</template> 

<script>
	export default {
		name: 'Button',

		props: [
			'title',
			'to',
			'target',
			'css',
			'item'
		]
	}
</script>

<style lang="scss">
	.button {
		&-wrap {
			position: relative;
			z-index: 0;
			display: inline-block;
			margin-bottom: 12.5px;

			.button-item:hover {	
				background-color: color(brand, accent);
				border-color: color(brand, accent);
				color: color(mono, black);
				
				.button-mask {
					animation: background-slide 2s infinite;
					animation-timing-function: linear;
					background-image: url('../assets/images/svgs/ears-tiled_accent.svg?inline');
				}
			}
		}

		&-item {
			display: inline-block;
			vertical-align: middle;
			border: 3px solid currentColor;
			color: color(mono, white);
			line-height: 53px;
			padding-left: 25px;
			padding-right: 25px;
			font-weight: 600;
			background-color: color(mono, black);
			box-shadow: 5px 5px 0 0px color(mono, black);

			@include media-breakpoint-up(md) {
				font-size: font(mid);
			}

			&:not([target="_blank"]):after {
				content: '\2192';
				margin-left: 7.5px;
				display: inline-block;
				vertical-align: middle;
			}

			&[target="_blank"]:after {
				content: '\2197';
				margin-left: 7.5px;
				display: inline-block;
				vertical-align: middle;
			}
		}

		&-mask {
			position: absolute;
			z-index: -1;
			top: 12.5px;
			left: 12.5px;
			width: 100%;
			height: 100%;
			background-image: url('../assets/images/svgs/ears-tiled_white.svg?inline');
			background-size: 88.5px;
			background-position: 0 0;
			background-repeat: repeat;
			opacity: 0.75;
		}
	}

	section:not([class*="bg--"]),
	section.bg--mono-white {
		.button-item {
			box-shadow: 5px 5px 0 0px color(mono, white);
			border-color: color(mono, black);

			.button-mask {
				background-image: url('../assets/images/svgs/ears-tiled.svg?inline');
			}

			&:hover {
				color: color(mono, white);

				.button-mask {
					background-image: url('../assets/images/svgs/ears-tiled_accent.svg?inline');
				}
			}
		}
	}
</style>