<template>
	<section v-if="is_visible" class="mentions bg--mono-white py-5">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-5 mb-2 mb-md-0">
					<Ears class="mentions-ears fill--brand-flat mb-2"/>
					<h2 class="font-responsive-small mb-4 color--brand-flat" v-html="data.title" />
				</div>

				<div class="col-12 col-md">
					<ul v-if="data.items">
						<li v-for="item in data.items" :key="item.name">
							<a :href="item.link.url" :title="item.link.title" rel="noreferrer" target="_blank" class="font-responsive-extra-small" v-html="item.name" />
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import Ears from '../assets/images/svgs/ears.svg?external';

	export default {
		name: 'Mentions',

		props: [ 'data' ],

		components: {
			Ears,
		},

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mentions { 
		a {
			font-weight: 900;
		}

		ul {
			&:hover {
				a {
					opacity: 0.5;
				}

				a:hover {
					opacity: 1;
					color: color( mono, black );
				}
			}
		}
	}

	.mentions-ears {
		max-width: 100px;

		@include media-breakpoint-up(md) {
			max-width: 150px;
		}
	}
</style>