<template>
	<section v-if="is_visible" class="gallery bg--mono-white py-5">
		<div class="container container--lg">
			<ul class="gallery-list row">
				<li class="col-12 mb-3" :class="'col-md-' + item.size" v-for="item in data.items" :key="item.src">
					<img loading="lazy" width="100" height="50" :src="require( '../assets/' + item.src )" :alt="item.alt">
				</li>
			</ul>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Gallery',

		props: [ 'data' ],

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>