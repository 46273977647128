<template>
	<section class="descriptor bg--mono-black py-5">
		<div class="container">
			<h2 class="descriptor-introduction__title mb-5 font-responsive-large" v-if="data.introduction_title" v-html="data.introduction_title"></h2>
			<div class="row">
				<div class="col-12 col-md-4 mb-4 mb-md-0">
					<h2 class="descriptor-title font-responsive" v-html="data.title"></h2>
				</div>

				<div class="col-12 col-md font-mid" v-html="$filters.autop(data.body)"></div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Descriptor',

		props: [ 'data' ],

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	body {
		counter-reset: section;
	}

	.descriptor {
		&-title {
			word-break: break-word;
			counter-increment: section;

			&:before {
				content: counter(section) '.';
				display: block;
				color: color(brand, accent);
			}
		}	

		p:last-child {
			margin-bottom: 0;
		}	
	}
</style>