<template>
	<section v-if="is_visible" class="definition bg--mono-white py-5">
		<div class="container">
			<Ears class="definition-ears mb-5" />
			<h2 class="definition-title font-responsive mb-0" v-html="data.title"></h2>
			<p class="definition-pronunciation mb-2 color--mono-light font-responsive-small"><strong v-html="data.pronunciation"></strong></p>
			<p class="definition-type mb-0"><i v-html="data.type"></i></p>

			<div class="definition-list mt-3" v-html="$filters.autop(data.body, 'list')"></div>
		</div>
	</section>
</template>

<script>
	import Ears from '../assets/images/svgs/ears.svg?external';

	export default {
		name: 'Definition',

		props: [ 'data' ],

		components: {
			Ears
		},

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" >
	.definition {
		&-ears {
			max-width: 150px;
		}

		&-list,
		&-list li {
			list-style: decimal;
			font-size: font(mid);
		}

		&-list {
			border-left: 4px solid color(mono, black); 
		}
	}
</style>