<template>
	<section v-if="is_visible" class="pair py-5" :class="typeof data.backgroundColor !== 'undefined' ? data.backgroundColor : 'bg--mono-white'">
		<div v-if="typeof data.introduction !== 'undefined'" class="pair-introduction">
			<div class="container" :class="typeof data.container !== 'undefined' ? data.container : false">
				<div class="row no-gutters justify-content-center">
					<h2 v-if="null !== data.introduction.title" class="ta--c mb-5 font-responsive" v-html="data.introduction.title"></h2>
				</div>
			</div>
		</div>

		<div class="container" :class="null !== data.container ? data.container : false">
			<div class="row align-items-center" :class="true === data.reverse ? 'flex-lg-row-reverse' : false" >
				<div class="col-12 col-lg-6 mb-5 mb-lg-0">
					<article class="pair-item pair-left pair-image">
						<img loading="lazy" width="505" height="675" :src="require( '../assets/images/' + data.image.src )" :alt="data.image.alt">
					</article>
				</div>

				<div class="col-12 col-lg-6">
					<article class="pair-item pair-right">
						<h2 class="pair-item_title font-responsive mb-0" v-html="data.title"></h2>
						<p class="pair-sub_title color--mono-dark mt-0" v-if="null !== data.sub_title ? data.sub_title : false" v-html="data.sub_title"></p>
						
						<div class="pair-item_copy mt-4" v-html="$filters.autop( data.body )"></div>

						<div class="mt-4" v-if="typeof data.link !== 'undefined'">
							<Button v-if="typeof data.link !== 'undefined'" :title="data.link.title" :to="data.link.url" css="large"></Button>
						</div>
					</article>
				</div>
			</div>
		</div>
	</section>
</template>		

<script>
	import Button from '@/components/Button';

	export default {
		name: 'Pair',
		
		components: {
			Button
		},

		props: [ 'data' ],

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.pair {
		&-item {
			margin-left: auto;
			margin-right: auto;
			max-width: 575px;

			&_copy {
				p:last-child {
					margin-bottom: 0;
				}
			}

			@include media-breakpoint-up(lg) {
				max-width: 90%;

				&.pair-left {
					margin-left: 0;
				}

				&.pair-right {
					margin-right: 0;
				}
			}
		}

		&-image {
			position: relative;
			z-index: 0;
			margin-bottom: 37.5px;

			img {
				display: block;
				box-shadow: 0 10px 0 0 color(mono, white);

				@include media-breakpoint-up(lg) {
					box-shadow: 10px 10px 0 0 color(mono, white);
				}
			}

			@include media-breakpoint-up(lg) {
				max-width: 85%;
			}

			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				top: 37.5px;
				width: 100%;
				height: 100%;
				background-position: -2px 0px;
				background-image: url('../assets/images/svgs/ears-tiled.svg?inline');
				background-size: 134px;
				background-repeat: repeat;
				opacity: 1;

				@include media-breakpoint-up(lg) {
					left: 37.5px;
				}
			}
		}

		.flex-lg-row-reverse {
			.pair-image {
				img {
					@include media-breakpoint-up(lg) {
						box-shadow: -10px 10px 0 0 color(mono, white);
					}
				}

				@include media-breakpoint-up(lg) {
					&:after {
						left: -37.5px;
					}
				}
			}

			@include media-breakpoint-up(lg) {
				.pair-left {
					margin-right: 0;
					margin-left: auto;
				}

				.pair-right {
					margin-right: auto;
					margin-left: 0;
				}
			}
		}
	}

	.bg--mono-black  {
		.pair-image {
			img {
				display: block;
				box-shadow: 0 10px 0 0 color(mono, black);

				@include media-breakpoint-up(lg) {
					box-shadow: 10px 10px 0 0 color(mono, black);
				}
			}

			&:after {
				background-image: url('../assets/images/svgs/ears-tiled_white.svg?inline');
			}
		}
	}

	.bg--mono-black .flex-lg-row-reverse {
		.pair-image img {
			@include media-breakpoint-up(lg) {
				box-shadow: -10px 10px 0 0 color(mono, black);
			}
		}
	}

	.pair-sub_title {
		margin-top: -2.5rem;
	}
</style>