<template>
	<section v-if="is_visible" class="hero bg--mono-black pt-5 py-4" :style="typeof data.colours !== 'undefined' ? 'background-color:' + data.colours.accent + ';'  : false">
		<div class="container">
			<div class="hero-breadcrumbs mb-5">
				<router-link class="color--mono-dark" :to="{ path: '/projects' }">&larr; Back to projects</router-link>
			</div>

			<div class="hero-content">
				<div class="hero-text">
					<Ears class="hero-ears mb-4"/>

					<h1 v-if="null !== data.title" class="font-responsive hero-title mb-0" v-html="data.title"></h1>
				</div>
			</div>

			<div class="row mt-5">
				<div class="col-12 col-md-4 mb-4 mb-md-0">
					<p class="hero-meta_title color--mono-dark font-mid mb-2">Services</p>

					<ul v-if="typeof data.meta !== 'undefined'" class="hero-meta_items">
						<li class="font-sub" v-for="item in data.meta" :key="item.path">
							<p class="mb-0">
								<router-link v-if="item.path" :to="{ path: item.path }" v-html="item.name"></router-link>
								<span v-else v-html="item.name"></span>
							</p>
						</li>
					</ul>
				</div>

				<div v-if="typeof data.description !== 'undefined'" class="hero-description col-12 col-md-8 mb-0">
					<p class="hero-meta_title color--mono-dark font-mid mb-2">About the project</p>

					<div class="font-sub" v-html="$filters.autop(data.description)"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	// eslint-disable-next-line no-unused-vars
	import Ears from '../assets/images/svgs/ears.svg?external';

	export default {
		name: 'ProjectIntroduction',

		props: [ 
			'data'
		],

		data() {
			return {
				wordsInterval: false
			}
		},

		components: {
			Ears,
		},

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.hero {
		position: relative;
		z-index: 0;

		&-content {
			max-width: 650px;

			@include media-breakpoint-up(lg) {
				max-width: 65%;
			}
		}

		&-ears {
			max-width: 150px;
			fill: color(mono, white);
		}

		&-title {
			span {
				color: color( brand, accent );
				text-decoration: underline;
				text-decoration-thickness: 6px;
				text-underline-offset: 6px;
			}
		}

		p {
			margin-bottom: 0;
		}
	}

	.hero-meta_title {
		font-weight: 500;
	}
</style>