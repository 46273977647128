<template>
	<section v-if="is_visible" class="contact bg--mono-black py-8">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-6 contact-header">
					<h2 class="contact-title font-responsive mb-4" v-html="data.title"></h2>

					<div class="mb-0 font-mid mb-4" v-if="data.body" v-html="$filters.autop(data.body)"></div>
				</div>
			</div>

			<Button :title="data.link.title" :to="data.link.url" :target="data.link.target" css="large"></Button>
		</div>
	</section>
</template>

<script>
	import Button from '@/components/Button';

	export default {
		name: 'Contact',

		components: {
			Button
		},

		props: [ 
			'data'
		],

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.contact {
		&-title {
			line-height: 1;
		}

		p > a {
			font-weight: 900;
			border-bottom: 3px solid currentColor;
			text-decoration: none;
		}
	}
</style>