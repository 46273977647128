<template>
    <header class="bg--mono-black p-2 p-md-3" id="masthead">
		<div class="row justify-content-between align-items-center">
			<div class="col-auto">
				<router-link class="logo" :to="{path: '/'}" title="Kaluna home">
					<Logo class="logo" />
				</router-link>
			</div>

			<div class="col-auto">
				<Navigation />
			</div>
		</div>
    </header>
</template>

<script>

import Logo from '@/assets/images/svgs/kaluna.svg?external';
import Navigation from '@/components/Navigation';

export default {
	name: 'Header',

	components: {
		Logo,
		Navigation,
	},

	mounted() {
		window.onscroll = () => {
			let scroll = window.scrollY;
			let sections = document.querySelectorAll('section');

			if ( sections.length > 0 && scroll >= sections[0].clientHeight ) {
				document.getElementById('masthead').classList.add('header-scrolled');
			} else {
				document.getElementById('masthead').classList.remove('header-scrolled');
			}
		};
	}
}
</script>

<style lang="scss" scoped>
	header {
		color: white;
		position: sticky;
		z-index: 5;
		top: 0;
		left: 0;
		width: 100%;

		&::v-deep .logo path:last-of-type {
			opacity: 1;
			display: inline-block;
			transform: translateX(0);
		}

		&::v-deep.header-scrolled {
			.logo path:last-of-type {
				transform: translateX(-20px);
				opacity: 0;
			}
		}
	}

	.logo::v-deep {
		display: inline-block;
		max-width: 125px;

		svg {
			width: 100%;
			fill: white;
			
			path {
				transition: all 333ms ease-in-out;
			}
		}
	}
</style>