<template>
	<ul class="socials row no-gutters mb-4">
		<li class="mr-4"><a rel="noreferrer" href="https://www.facebook.com/kalunaweb" target="_blank" title="Follow Kaluna on Facebook"><Facebook/></a></li>
		<li class="mr-4"><a rel="noreferrer" href="https://www.instagram.com/kalunaweb" target="_blank" title="Follow Kaluna on Instagram"><Instagram/></a></li>
		<li><a rel="noreferrer" href="http://www.twitter.com/kalunaweb" target="_blank" title="Follow Kaluna on Twitter"><Twitter/></a></li>
	</ul>
</template>

<script>
	import Facebook from '@/assets/images/svgs/facebook.svg?external';
	import Instagram from '@/assets/images/svgs/instagram.svg?external';
	import Twitter from '@/assets/images/svgs/twitter.svg?external';

	export default {
		name: 'Footer',
		components: {
			Facebook,
			Instagram,
			Twitter,
		}
	}
</script>

<style lang="scss" scoped>
	.socials {
		svg {
			
			width: auto;
			height: 30px;
		}
	}
</style>