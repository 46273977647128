<template>
    <Header/>
    <main>
        <router-view :key="$route.fullPath" />
    </main>
    <Footer/>
    <div id="loader">
        <Ears />
    </div>
</template>

<style lang="scss">
    main {
        overflow: hidden;
    }

    #loader {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: color( mono, white );
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        svg {
            width: 120px;
        }

        &.inactive {
            animation: fade-out 500ms 250ms ease-in-out forwards;
        }

        &.hidden {
            z-index: -1;
        }
    }
</style>

<script>
    import Ears from '@/assets/images/svgs/ears.svg?external';

    export default {
        components: {
            Ears
        },

        renderTriggered() {
            if ( false === document.getElementById('mastnav').classList.contains('nav-active') ) {
                document.getElementById('loader').classList.remove('inactive');
            }
        },

        updated() {
            document.getElementById('loader').classList.add('inactive');

            setTimeout( () => {
                document.getElementById('loader').classList.add('hidden');
            }, 750 );
        }
    }
</script>