<template>
	<section v-if="is_visible" class="single bg--mono-black" :style="typeof data.colours !== 'undefined' ? 'background-color: ' + data.colours.accent + ';' : false">
		<div class="single-image">
			<img loading="lazy" width="505" height="675" :src="require( '../assets/' + data.image.src )" :alt="data.image.alt">
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Single',
		
		props: [ 'data' ],

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		},

		mounted() {
			let singles = document.querySelectorAll('.single');

			if ( singles.length > 1 ) {
				singles.forEach( single => {
					if ( typeof single.previousElementSibling !== 'undefined' ) {
						single.previousElementSibling.classList.remove('py-5');
						single.previousElementSibling.classList.add('pt-5', 'pb-11');
					}
				} );
			}
		}
	}
</script>

<style lang="scss" scoped>
	.single {
		position: relative;
		z-index: 0;
		margin-top: -$spacer * 4;
		margin-bottom: -$spacer * 4;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			bottom: 0;
			left: 0;
			background-color: color( mono, white );
			height: 50%;
			width: 100%;
		}
	}

	.single-image {
		margin-left: auto;
		margin-right: auto;
		display: block;
		max-width: 2500px;

		img {
			display: block;
			margin-left: -5%;
			margin-right: -5%;
			width: 110%;
			max-width: 110%;

			@include media-breakpoint-up(xxxxl) {
				margin-left: 0;
				margin-right: 0;
				width: 100%;
				max-width: 100%;
			}
		}
	}
</style>