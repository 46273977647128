<template>
    <footer class="py-5">
		<div class="container">
			<Socials v-if="'Contact' !== $route.name" />
			<Details v-if="'Contact' !== $route.name" />
			<Sitemap />

			<p class="mb-0">&copy; Kaluna {{ new Date().getFullYear() }}</p>
        </div>
    </footer>
</template>

<script>
	// partials
	import Details from '@/components/partials/Details';
	import Sitemap from '@/components/partials/Sitemap';
	import Socials from '@/components/partials/Socials';

	export default {
		name: 'Footer',
		components: {
			Details,
			Sitemap,
			Socials,
		}
	}
</script>