<template>
	<section v-if="is_visible" class="hero" :class="typeof data.backgroundColor !== 'undefined' ? data.backgroundColor : 'bg--mono-black'" :style="typeof data.colours !== 'undefined' ? 'background-color:' + data.colours.accent + ';'  : false">
		<div class="container">
			<div class="hero-content">
				<div class="hero-text">
					<Ears class="hero-ears mb-4"/>

					<h1 v-if="null !== data.title" class="font-responsive-massive hero-title mb-0" v-html="data.title"></h1>
					<div v-if="typeof data.description !== 'undefined'" class="hero-description font-responsive-extra-small mb-0" v-html="$filters.autop(data.description)"></div>
					
					<div v-if="typeof data.body !== 'undefined'" class="hero-body font-sub mt-3 mb-0" v-html="$filters.autop(data.body)"></div>
				</div>

				<div v-if="typeof data.link !== 'undefined'" class="hero-button mt-4">
					<Button :title="data.link.title" :to="data.link.url" css="large"></Button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	// eslint-disable-next-line no-unused-vars
	import Ears from '../assets/images/svgs/ears.svg?external';

	export default {
		name: 'Hero',

		props: [ 
			'data'
		],

		data() {
			return {
				wordsInterval: false
			}
		},

		components: {
			Ears,
		},

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	.hero {
		position: relative;
		z-index: 0;
		padding-top: 6rem;
		padding-bottom: 6rem;

		@include media-breakpoint-up(md) {
			padding-top: 15%;
			padding-bottom: 15%;
		}

		@include media-breakpoint-up(xl) {
			padding-top: 10%;
			padding-bottom: 10%;
		}

		&-content {
			max-width: 650px;
			@include media-breakpoint-up(lg) {
				max-width: 65%;
			}
		}

		&-ears {
			max-width: 100px;
			fill: color(mono, white);

			@include media-breakpoint-up(sm) {
				max-width: 150px;
			}
		}

		&.bg--mono-white {
			.hero-ears {
				fill: color(mono, black);
			}
		}

		&-title {
			span {
				color: color( brand, accent );
				text-decoration: underline;
				text-decoration-thickness: 6px;
				text-underline-offset: 6px;
			}
		}

		p {
			margin-bottom: 0;
		}
	}
</style>