<template>
	<ul class="details row no-gutters mb-3">
		<li><a class="color--mono-black" href="tel:07934359933" title="Call Kaluna">07934 359 933</a></li>
		<li><a class="color--mono-black" href="mailto:hello@kaluna.co.uk" title="E-mail Kaluna">hello@kaluna.co.uk</a></li>
	</ul>
</template>

<style lang="scss" scoped>
	.details {
		&:not(.social) li:not(:last-child) {
			color: color(mono, black);

			&:after {
				content: '|';
				display: inline-block;
				margin: 0 10px;
			}
		}

		a {
			font-weight: 300;
		}
	}
</style>