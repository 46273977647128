<template>
	<section v-if="is_visible" class="content py-5" :class="typeof data.backgroundColor !== 'undefined' ? data.backgroundColor : 'bg--mono-white'">
		<div class="container" :class="typeof data.container !== 'undefined' ? data.container : null">
			<div class="content-wrapper">
				<component v-if="typeof data.title !== 'undefined'" :is="data.title_tag || 'h2'" class="content-title mb-4" :class="typeof data.title_size !== 'undefined' ? data.title_size : null" v-html="data.title"></component>

				<div class="content-text" v-if="typeof data.body !== 'undefined'" v-html="$filters.autop(data.body)"></div>

				<div v-if="typeof data.link !== 'undefined'" class="button-wrap mt-4">
					<Button :title="data.link.title" :to="data.link.url" css="large"></Button>
				</div>
			</div>
		</div>
	</section>
</template>	

<script>
	import Button from '@/components/Button';

	export default {
		name: 'Content',
		
		components: {
			Button
		},

		props: [ 
			'data'
		],

		computed: {
			is_visible() {
				return typeof this.data !== 'undefined';
			}
		}
	}
</script>

<style lang="scss" scoped>
	section.content::v-deep p:last-child {
		margin-bottom: 0;
	}

	.content-title {
		max-width: 1000px;
	}

	.content-text {
		max-width: 1000px;
	}
</style>