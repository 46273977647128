import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
const app = createApp(App);
app.use(router);

// components.
import components from '@/components';

Object.values( components ).forEach( component => {
	app.component(component.name, component);
} );

// filters.
app.config.globalProperties.$filters = {
	autop( string, tag ) {
		const split = string.replaceAll('<br/>', "\n\n").split('\n\n');
		let new_string = '';

		if ( 'list' === tag ) {
			new_string += '<ul class="autop-list">';
		}

		split.forEach(split_string => {
			split_string = split_string.trim();

			if ( 'list' === tag ) {
				new_string += '<li>'+ split_string +'</li>'
			} else {
				new_string += '<p>'+ split_string +'</p>'
			}
		});

		if ( 'list' === tag ) {
			new_string += '</ul>';
		}

		return new_string;
	},

	sanitize_string( string ) {
		string = string.replaceAll(' ', '_');
		string = string.toLowerCase();

		return string;
	}
}

app.config.globalProperties.$functions = {
	sectionPadding() {
		const sections = document.querySelectorAll('main section');

		if (sections.length > 0) {
			for (var i = sections.length - 1; i >= 0; i--) {
				let section = sections[i];

				let nextSection = section.nextElementSibling;

				let currentStyle = window.getComputedStyle(section);
				let currentBg = currentStyle.getPropertyValue('background-color');

				if ( nextSection ){
					let nextSectionStyle = window.getComputedStyle(nextSection);
					let nextSectionBg = nextSectionStyle.getPropertyValue('background-color');

					if ( currentBg === nextSectionBg ) {
						if ( section.classList.contains('py-5') ) {
							section.classList.remove('py-5');
							section.classList.add('pt-5');
						}
					}
				}
			}	
		}
	}
}

app.mount('#app');